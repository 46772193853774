import {
  getDefaultWallets,
  RainbowKitProvider,
  darkTheme,
  lightTheme,
  connectorsForWallets,
  getDefaultConfig,
} from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  ledgerWallet,
  metaMaskWallet,
  okxWallet,
  coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { zenGoWallet } from './connectors/ZenGoWallet';
// import { fortmaticWallet } from './connectors/FortmaticWallet';
// import { FortmaticConnector } from './connectors/FortmaticConnector';
import {  http, createConfig, WagmiProvider } from 'wagmi';
import { type Chain } from 'viem';
import {
  mainnet,
  polygon,
  arbitrum,
  blast,
  bsc,
  celo,
} from 'wagmi/chains';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useDarkMode } from 'hooks/useDarkMode';
import ChainProvider from './ChainProvider';
import { ChainId, chainSrc } from 'constants/constants';
import useSelectedChain from 'hooks/useSelectedChain';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const INFURA_ID = process.env.INFURA_ID || process.env.GATSBY_INFURA_ID;
// TODO: You need to get a wallet connect id from https://cloud.walletconnect.com/:
const WALLET_CONNECT_ID =
  process.env.WALLET_CONNECT_ID || process.env.GATSBY_WALLET_CONNECT_ID;
const FORTMATIC_KEY =
  process.env.FORTMATIC_KEY || process.env.GATSBY_FORTMATIC_KEY;

// Used to remove testnets when in prod build:
const IS_PROD = process.env.NODE_ENV !== 'development';

const getChainCustomProps = (chainId: number): ChainCustomProps => {
  switch (chainId) {
    case ChainId.Mainnet:
      return {
        color:
          'linear-gradient(90deg, rgba(60,60,60,1) 0%, rgba(34,34,34,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.Goerli:
      return {
        color:
          'linear-gradient(90deg, rgba(0,226,216,1) 0%, rgba(0,181,173,1) 100%)',
        env: 'testnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'png'),
      };
    case ChainId.Polygon:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.Mumbai:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'testnet',
        icon: chainSrc.replace('[chainid]', '137').replace('[ext]', 'svg'),
      };
    case ChainId.Arbitrum:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.Bsc:
      return {
        color:
          'linear-gradient(90deg, rgba(242,185,32,1) 0%, rgba(242,185,32,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.Celo:
      return {
        color:
          'linear-gradient(90deg, rgba(242,185,32,1) 0%, rgba(242,185,32,1) 100%)',
        env: 'mainnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    case ChainId.ArbitrumGoerli:
      return {
        color:
          'linear-gradient(90deg, rgba(161,128,217,1) 0%, rgba(130,71,229,1) 100%)',
        env: 'testnet',
        icon: chainSrc
          .replace('[chainid]', chainId.toString())
          .replace('[ext]', 'svg'),
      };
    default:
      return {
        color: '',
        env: 'testnet',
        icon: '',
      };
  }
};

const addChainsCustomConfigs = (chains: Chain[]): CustomChain[] => {
  return chains.map((chain) => ({
    ...chain,
    ...getChainCustomProps(chain.id),
  }));
};

export const skaleEuropa = {
  id: 2046399126,
  name: 'Skale | Europa DeFi Hub',
  nativeCurrency: { name: 'sFUEL', symbol: 'sFUEL', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://mainnet.skalenodes.com/v1/elated-tan-skat'] },
  },
  blockExplorers: {
    default: { name: 'SKALE Explorer', url: 'https://elated-tan-skat.explorer.mainnet.skalenodes.com/' },
  },
} as const satisfies Chain

const chainsConfiguration = getDefaultConfig({
  appName: 'Ichi.org',
  projectId: WALLET_CONNECT_ID as string,
  chains: ([
    mainnet,
    polygon,
    arbitrum,
    blast,
    bsc,
    celo,
    skaleEuropa,
  ]),
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [arbitrum.id]: http(),
    [blast.id]: http(),
    [bsc.id]: http(),
    [celo.id]: http(),
    [skaleEuropa.id]: http(),
  },
},
);

export const { chains } = chainsConfiguration;

// const fortmaticConnector = new FortmaticConnector({
//   options: { apiKey: FORTMATIC_KEY as string },
//   chains: [mainnet, polygon, goerli, polygonMumbai],
// });

const { wallets } = getDefaultWallets({
  appName: 'Ichi.org',
  projectId: WALLET_CONNECT_ID as string,
});

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [rainbowWallet, metaMaskWallet, walletConnectWallet, ledgerWallet, okxWallet, coinbaseWallet, zenGoWallet],
    },
  ],
  {
    projectId: WALLET_CONNECT_ID as string,
    appName: 'Ichi.org',
  },
);

export const wagmiConf = createConfig({
  connectors,
  chains: ([
    mainnet,
    polygon,
    arbitrum,
    blast,
    bsc,
    celo,
    skaleEuropa,
  ]),
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [arbitrum.id]: http(),
    [blast.id]: http(),
    [bsc.id]: http(),
    [celo.id]: http(),
    [skaleEuropa.id]: http(),
  },
});

const WalletProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = new QueryClient()

  return (
    <WagmiProvider  config={wagmiConf}>
      <QueryClientProvider client={queryClient}>
        <ChainProvider>
          <Provider>{children}</Provider>
        </ChainProvider>
      </QueryClientProvider>
    </WagmiProvider >
  );
};

export default WalletProvider;

const Provider: FC<PropsWithChildren> = ({ children }) => {
  const { isDarkMode } = useDarkMode();
  const theme: ReturnType<typeof darkTheme | typeof lightTheme> = useMemo(
    () => (isDarkMode ? darkTheme() : lightTheme()),
    [isDarkMode]
  );
  const { chainId } = useSelectedChain();
  const selectedChain = useMemo(
    () => chains.find((c) => c.id === chainId) || chains[0],
    [chainId]
  );

  return (
    <RainbowKitProvider initialChain={selectedChain} theme={theme}>
      {children}
    </RainbowKitProvider>
  );
};

type ChainCustomProps = {
  color: string;
  env: 'mainnet' | 'testnet';
  icon: string;
};

export type CustomChain = Chain &
  ChainCustomProps & {
    unsupported?: boolean | undefined;
  };
